import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    )
  }),
)

Textarea.displayName = 'Textarea'

const Container = styled.textarea`
  ${({ theme }) => {
    return css`
      width: 100%;
      height: 11.875rem;
      padding: 1.25rem;
      background: ${rgba(theme.colors.variants.primaryDark, 0.03)};
      color: ${theme.colors.variants.primaryDark};
      font-family: ${theme.fontFamily.paragraph};
      font-weight: normal;
      font-size: 1.5rem;
      resize: none;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        opacity: 0.5;
      }

      @media (max-width: 1199px) {
        padding: 1.25rem 0.6875rem;
        font-size: 1.125rem;
      }
    `
  }}
`
