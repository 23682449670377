import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  checked?: boolean
  error?: boolean
  name: string
  label?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    {
      checked,
      error = false,
      name,
      label,
      required,
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    const id = `#form-${name}`

    return (
      <Container dial={4} inline row>
        <InputField
          ref={ref}
          checked={checked}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)

Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.label};
  font-weight: normal;
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
  line-height: 1.3125rem;
  cursor: pointer;
`

const InputField = styled.input`
  ${({ theme }) => {
    return css`
      position: absolute;
      opacity: 0;
      visibility: hidden;
      z-index: -1;

      &:checked ~ label {
        &:before {
          border-color: ${theme.colors.variants.primaryDark};
        }
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    `
  }}
`

const Label = styled.label<ContainerProps>`
  ${({ theme, error }) => {
    return css`
      color: ${theme.colors.variants.primaryDark};
      padding-left: 2.125rem;
      text-align: left;

      &:before,
      &:after {
        content: '';
        position: absolute;
        transition: 0.3s ease-in-out;
      }

      &:before {
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        border: 0.0625rem solid
          ${error
            ? theme.colors.variants.danger
            : theme.colors.variants.primaryDark};
      }

      &:after {
        top: 0.3125rem;
        left: 0.3125rem;
        width: 0.625rem;
        height: 0.625rem;
        background: ${theme.colors.variants.primaryDark};
        opacity: 0;
      }

      a {
        font-weight: 700;

        @media (max-width: 768px) {
          pointer-events: none;
        }
      }
    `
  }}
`

interface ContainerProps {
  error: boolean
}
