import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  onClick: () => void
}

export const Close = memo(function Close({ onClick }: Props) {
  return (
    <Container dial={5} row onClick={onClick}>
      <Icon src="/icon_close.svg" width="14" height="14" />
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  height: 4.375rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  cursor: pointer;
  z-index: 4;
`

const Icon = styled.img``
